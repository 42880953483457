.media-library {
  width: 100%;
}

.media-library .media-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: flex-start;
}

.media-library .media-header .searchbar {
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 0.5rem;
  border: 1px solid var(--color-secondary);
  padding: 0.3rem 0.7rem;
}

.media-library .media-header .searchbar-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.media-library .media-header .searchbar > i {
  font-size: 1.5em;
  color: var(--color-active);
}

.media-library .media-header .searchbar > input.search-input {
  border: none;
  padding-left: 1rem;
  background-color: transparent;
}

.media-library .media-header .searchbar > input.search-input::placeholder {
  color: var(--color-secondary);
}

.media-library .media-header .searchbar > input.search-input:focus {
  outline: none;
}

.media-library .media-header .btn-primary {
  color: #fff;
  background-color: var(--color-primary);
  border-radius: 10px;
}

.media-library .media-header .btn-primary:hover {
  opacity: 0.8;
}

.media-library .media-header .actions__wrapper {
  display: flex;
  column-gap: 12px;
}

.media-library .media-header .actions__wrapper .media__delete {
  border-radius: 10px;
  background-color: #ff4d4f;
  color: #fff;
}

.media-library .media-header .actions__wrapper .media__delete:hover {
  background-color: #ff7875;
}

.media-library .media-subheader {
  display: flex;
  justify-content: flex-end;
}

.media-library .media-subheader .media-button-group > button.active {
  background-color: var(--color-active);
}

.media-library .media-subheader .media-button-group > button {
  border: none;
  background-color: var(--color-secondary);
  color: #fff;
  padding: 0.25rem 0.8rem;
  cursor: pointer;
}

.media-library .media-subheader .media-button-group > button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.media-library .media-subheader .media-button-group > button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.media-library .media-content {
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.media-library .title {
  color: var(--color-primary);
  font-size: 1.4rem;
  text-transform: capitalize;
}

.media-library .media-content .media-card {
  /* width: 25%; */
  width: 100%;
  /* width: 100%; */
  position: relative;
  padding: 1rem 0.5rem;
  margin-right: 0 !important;
}

.media-library .media-content .media-card:hover .media-overlay {
  opacity: 1;
}

/* .media-library .media-content .media-card:not(:last-child) {
  margin-right: 1rem;
} */

.media-library .media-content .media-card > img,
.media-library .media-content .media-card > video {
  width: 300px;
  height: 400px;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
  border: 1px solid var(--color-stroke);
}

/* .media-library .media-overlay {
  position: absolute;
  background-color: #fff;
  width: 300px;
  height: auto;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  display: flex;
  transition: all 0.3s ease-in-out;
  bottom: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--color-stroke);
  white-space: pre-wrap;
}

.media-library .media-overlay h3 {
  color: var(--color-primary);
  font-size: 1.2rem;
}

.media-library .media-overlay h5 {
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: 400;
}

.media-library .media-overlay p {
  color: var(--color-navbar);
  font-size: 0.8rem;
}

.media-library .media-overlay .media-overlay-button-group {
  display: flex;
  justify-content: flex-end;
  z-index: 9;
}

.media-library .media-overlay .media-overlay-button-group > button,
.media-library .media-overlay .media-overlay-button-group > a {
  cursor: pointer;
  border: none;
  background-color: #fff;
  color: var(--color-secondary);
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  display: inline-block;
  font-size: 1.2em;
}

.media-library
  .media-overlay
  .media-overlay-button-group
  > button:not(:last-child),
.media-library .media-overlay .media-overlay-button-group > a:not(:last-child) {
  margin-right: 0.5rem;
} */

.media-library .media-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.media-library .empty-media-state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}

.media-library .media-library__wrapper {
  border: 5px;
}

.media-library .media-library__wrapper .media-library__header {
  background-color: var(--color-primary);
}

.media-library .media-library__wrapper .media-library__header:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.media-library .media-library__wrapper .media-library__header:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.media-library
  .media-library__wrapper
  .media-library__header
  .ant-collapse-header:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s all ease;
}

.media-library
  .media-library__wrapper
  .media-library__header
  .ant-collapse-header {
  color: #fff;
}

.media-library .media-library__wrapper .media-library__card {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  padding: 0.5rem 1rem;
}

@media (min-width: 900px) {
  .media-library .media-library__wrapper .media-library__card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .media-library .media-library__wrapper .media-library__card {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1400px) {
  .media-library .media-library__wrapper .media-library__card {
    grid-template-columns: repeat(4, 1fr);
  }
}

.media-library__card-item {
  position: relative;
  height: 300px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  z-index: 9999999;
  flex-direction: column;
  border: 1px solid var(--color-stroke);
  white-space: pre-wrap !important;
  overflow: hidden;
  pointer-events: auto !important;
}

.media-library
  .media-library__wrapper
  .media-library__card
  .media-library__card-calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.media-library
  .media-library__wrapper
  .media-library__card
  .media-library__card-calendar-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  font-family: Roboto, sans-serif;
}

.media-library
  .media-library__wrapper
  .media-library__card
  .media-library__card-calendar-wrapper {
  display: flex;
  align-items: center;
}

.media-library
  .media-library__wrapper
  .media-library__card
  .media-library__card-calendar-logo {
  margin-right: 12px;
}

.media-library
  .media-library__wrapper
  .media-library__card
  .media-library__card-calendar-edit {
}

.playlist
  .media-library__wrapper
  .media-library__card
  .media-library__card-calendar-edit-logo {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
}

.media-library
  .media-library__wrapper
  .media-library__card
  .media-library__card-description {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: normal;
  z-index: 30;
  width: 100%;
}

.media-library
  .media-library__wrapper
  .media-library__card
  .media-library__card-description
  span {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.media-library
  .media-library__wrapper
  .media-library__card
  .media-library__card-description
  p {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  display: block;
  white-space: pre-wrap;
}

.media-library__card-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.media-library__actions {
  z-index: 30;
}
.ant-popover-inner-content {
  padding: 0.5rem 0.5rem !important;
}

.media-library__media-popover {
  display: flex;
  column-gap: 0.25rem;
}

.media-library__media-preview {
  padding: 0.25rem 0.5rem;
  border-bottom-left-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--color-primary);
  color: #fff;
}

.media-library__media-delete {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  background-color: hsla(0, 62%, 55%, 1);
  color: #fff;
  border-left: 1px solid #fff;
}

.media-library__media-more {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: hsl(212, 46%, 25%);
  color: #fff;
  border-left: 1px solid #fff;
}

.img-overlay {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 80%;
  bottom: 0px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.media-library__card-img {
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.media-library__card-video {
  z-index: 10;
}

.media-library__card-info {
  z-index: 30;
  cursor: default;
  text-align: center;
}

.media-library__card-type {
  background-color: hsl(168, 76%, 36%);
  color: #fff;
  padding: 0.25rem 0.5rem;
  display: inline-block;
  border-bottom-right-radius: 10px;
}

.media-library__card-order {
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.25rem 0.5rem;
  display: inline-block;
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__options {
  display: flex;
  margin-bottom: 12px;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__options
  .media-library__checkbox {
  display: flex;
  column-gap: 12px;
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__action-buttons {
  display: flex;
  column-gap: 12px;
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__action-buttons
  .media-library__options
  .media-library__checkbox
  .media-library__checkbox-title {
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__options
  .media-library__action-buttons
  > .media-library__add-file-btn {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__options
  .media-library__action-buttons
  > .media-library__add-file-btn:hover {
  opacity: 0.8;
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__options
  .media-library__action-buttons
  > .media-library__set-time-btn {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__options
  .media-library__action-buttons
  > .media-library__set-time-btn:hover {
  opacity: 0.8;
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__options
  .media-library__action-buttons
  > .media-library__delete-file-btn {
  color: #fff;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.media-library
  .media-library__header
  .media-library__panel
  .media-library__options
  .media-library__action-buttons
  > .media-library__delete-file-btn:hover {
  opacity: 0.8;
}

.media-library .media-library__card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding: 0.5rem 1rem;
}

/* .media-library .media-library__card .media-library__card-item {
  position: relative;
  height: 300px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-stroke);
  cursor: default;
  align-items: center;
  justify-content: center;
}

.media-library .media-library__card .media-library__card-item .ant-tag {
  z-index: 5;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.media-library .media-library__card .media-library__card-item ::selection {
  background: none !important;
  color: none !important;
} */

.media-library__card-item:active,
.media-library__card-item:hover {
  cursor: grab;
}

.media-library__card-item:active,
.media-library__card-item:hover {
  cursor: grabbing !important;
}

.media-library__card-item .ant-tag {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.media-library__card-item ::selection {
  background: none !important;
  color: none !important;
}

.media-library__card-item .img-overlay {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 80%;
  bottom: 0px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.media-library__card-item .checked-overlay {
  position: absolute;
  z-index: 100;
  inset: 0;
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.media-library__card-item .ant-tag-checkable-checked {
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.media-library__card-item .checked-overlay > .icon-checked {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.media-library__card-calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.media-library__card-calendar-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  font-family: Roboto, sans-serif;
}

.media-library__card-calendar-wrapper {
  display: flex;
  align-items: center;
}

.media-library__card-calendar-logo {
  margin-right: 12px;
}

.media-library__card-calendar-edit {
}

.media-library__card-calendar-edit-logo {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
}

.media-library__card-description {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: normal;
  z-index: 30;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -52.78%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.media-library__card-description span {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: block;
  white-space: pre-wrap;
}

.media-library__card-description p {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  display: block;
  white-space: pre-wrap;
}

/* .media-library .media-library__card .media-library__card-item .img-overlay {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 80%;
  bottom: 0px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.media-library .media-library__card .media-library__card-item .checked-overlay {
  position: absolute;
  z-index: 100;
  inset: 0;
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.media-library .media-library__card .ant-tag-checkable-checked {
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.media-library
  .media-library__card
  .media-library__card-item
  .checked-overlay
  > .icon-checked {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.media-overlay-button-group {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  z-index: 50;
  padding: 10px;
}

.media-overlay-button-group > button,
.media-overlay-button-group > a {
  cursor: pointer;
  border: none;
  background-color: #fff;
  color: var(--color-secondary);
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  display: inline-block;
  font-size: 1.2em;
}
