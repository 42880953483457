.screen {
  min-height: 60vh;
}

.screen-content {
  display: flex;
}

.screen-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.screen-column:not(:last-child) {
  padding-right: 3rem;
}

.screen-title {
  color: var(--color-primary);
  font-size: 1.2em;
  font-weight: 500;
}

.screen-table {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
}
.screen-button-update {
  display: flex;
  flex-direction: row;
}

.screen-table > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.screen-table > div > div:first-child {
  color: var(--color-black);
  font-weight: 500;
}

.screen-table > div > div:last-child {
  color: var(--color-black);
}

.screen-p {
  color: var(--color-black);
}

.screen-list > div:first-child {
  margin-top: 0px;
  padding-top: 0px;
}

.screen-list > div {
  margin: 1rem 0px;
  padding: 1rem 0px;
}

.screen-list > div:not(:last-child) {
  border-bottom: 1px solid var(--color-stroke);
}

.screen-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.screen-shoot {
  width: 100%;
  min-height: 200px;
  height: 100%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border: 2px dashed var(--color-stroke);
}

.screen-shoot > i {
  font-size: 3rem;
  color: var(--color-secondary);
}

.screen-button {
  border: none;
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.5rem 0px;
  border-radius: 10px;
  cursor: pointer;
}

.install-button {
  border: none;
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.5rem 1em;
  margin-right: 1em;
  border-radius: 10px;
  cursor: pointer;
}

.screen-button-loading {
  border: none;
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.5rem 0px;
  border-radius: 10px;
  cursor: pointer;
}

.screen-button:hover {
  opacity: 0.8;
}

.screen-loading {
  width: 96%;
  background-color: var(--color-background);
  position: absolute;
  z-index: 2;
  height: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-black);
  font-size: 1.5em;
  font-weight: 100;
}
