.media-form {
  width: 100%;
}

.media-form .form-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.media-form .form-wrapper > div:first-child {
  width: 100%;
  margin-right: 1rem;
}

.media-form .form-wrapper > div:last-child {
  width: 100%;
  margin-left: 1rem;
}

.media-form .form-wrapper .form > .form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
  color: var(--color-black);
  width: 100%;
}

.media-form .form-wrapper .form {
  margin-bottom: 2rem !important;
}

.media-form .upload-image-preview {
  position: relative;
  width: 140px;
}

.media-form .upload-image-preview > img,
.media-form .upload-image-preview > video {
  width: 140px;
  border: 2px dashed var(--color-secondary);
  border-radius: 10px;
  min-height: 100px;
}

.media-form .upload-image-preview > .btn-remove {
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: var(--color-danger);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.media-form .upload-drag-and-drop {
  background-color: #fff;
  padding: 1.5rem;
  width: 230px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px dashed var(--color-secondary);
  border-radius: 10px;
  cursor: pointer;
}

.media-form .upload-drag-and-drop > i {
  font-size: 1.5em;
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--color-secondary);
}

.media-form .upload-drag-and-drop > p {
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: var(--color-secondary);
}

.media-form .upload-drag-and-drop:hover,
.media-form .upload-drag-and-drop:hover > i,
.media-form .upload-drag-and-drop:hover > p,
.media-form .upload-drag-and-drop.active,
.media-form .upload-drag-and-drop.active > i,
.media-form .upload-drag-and-drop.active > p {
  border-color: var(--color-active);
  color: var(--color-active);
}

.media-form .button-wrapper {
  margin-top: 2rem;
}

.media-form .button-wrapper .button-primary {
  margin-right: 1rem;
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.media-form .button-wrapper .button-secondary {
  margin-right: 1rem;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.media-form .form-group {
  display: flex;
  align-items: flex-start;
}

.media-form .form input[type='color'] {
  background-color: rgb(243, 156, 18);
  border: none;
  width: 2rem;
  height: 2rem;
  padding: 0px;
  border-radius: 5px;
}

.media-form .input-group {
  display: flex;
  align-items: center;
}

.media-form .input-group > div:last-child {
  background-color: var(--color-stroke);
  padding: 0.3rem 1em;
}

.media-form .card-adsign .card-title > p {
  font-weight: normal;
}

.media-form .card-adsign-content .button-link {
  background-color: transparent;
}

.media-form .card-checkbox span {
  font-weight: normal;
}

.media-form .card-adsign-content .copy-popup {
  right: 0;
}
