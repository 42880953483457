.justify-left {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.button-delete {
  color: var(--color-danger);
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.button-delete:hover {
  opacity: 0.8;
}
