.is-user-avatar {
  &.has-max-width {
    max-height: 7rem;
  }

  .user-avatar {
    height: 100%;
    max-height: 7rem;
    max-width: 7rem;
  }
}

.field-body {
  flex-grow: 3;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #3273dc;
  right: 1.125em;
  z-index: 1 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff !important;
}

.range-picker {
  display: flex;
}

.range-picker .range-picker-from,
.range-picker .range-picker-from:focus {
  border-right: none;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  outline: none;
}

.range-picker .range-picker-to,
.range-picker .range-picker-to:focus {
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  outline: none;
}

.range-picker .range-picker-from:focus,
.range-picker .range-picker-to:focus {
  border-color: #3273dc;
}

.range-picker-from::after {
  content: '\F0054';
  font-family: 'MaterialDesignCommunity';
}

.react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  div.preview {
    display: none;
  }
}

.card-adsign {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin: 20px 0px;
  max-height: 500px;
  overflow-y: auto;
}

.card-title {
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;
  p {
    font-weight: bold;
  }
}

.card-adsign-content {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
  }
  .card-checkbox {
    input[type='checkbox'] {
      margin-right: 10px;
    }
    label {
      font-weight: bold;
    }
  }
  .hour-wrapper {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      margin-bottom: 10px;
      .hour-input {
        padding: 10px;
        width: 80px;
        margin: 0px 5px;
        border: 1px solid #e1e1e1;
      }
      .hour-input:focus {
        outline: none;
        border-color: #3273dc;
      }
    }
  }
  .card-button-wrapper {
    position: relative;
  }
  .button-link {
    background-color: #fff;
    border: none;
    font-size: 1.5em;
    color: #aaa;
    cursor: pointer;
    outline: none;
  }
  .button-blue {
    background-color: #3273dc;
    color: #fff;
    padding: 8px;
    border: none;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
  }
  .copy-popup {
    position: absolute;
    width: 250px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #e1e1e1;
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.1);
    right: -220px;
    > p {
      margin-bottom: 10px;
      font-size: 0.6em;
      text-transform: uppercase;
      font-weight: bold;
      color: #aaa;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      label {
        cursor: pointer;
      }
    }
  }
}
