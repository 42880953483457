.dashboard {
  padding: 2% 3%;
  display: flex;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  flex-direction: column;
}

.dashboard-header {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-active);
}

.dashboard-header .dashboard-organization {
  min-width: 300px;
}

.dashboard-header .dashboard-organization span {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: var(--color-active);
  margin-bottom: 0.5rem;
}

.dashboard-header .dashboard-service {
  min-width: 200px;
  text-align: center;
}

.dashboard-header .dashboard-service span {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: var(--color-active);
  margin-bottom: 0.5rem;
}

.dashboard .dashboard-span:hover {
  cursor: pointer;
}

.dashboard-header .dashboard-directory {
  min-width: 200px;
  text-align: center;
}

.dashboard-header .dashboard-directory span {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: var(--color-active);
  margin-bottom: 0.5rem;
}

.dashboard .dashboard-table {
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.dashboard .dashboard-table > .title {
  font-size: 1.2em;
  color: var(--color-active);
  margin-bottom: 0.5rem;
}

.dashboard .dashboard-table > .dashboard-row-wrapper > .dashboard-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: hsl(0, 0%, 35%);
  padding-top: 1rem;
  font-weight: 500;
  padding-bottom: 1rem;
}

/* .dashboard
  .dashboard-table
  > .dashboard-row-wrapper
  > .dashboard-row:not(:last-child) {
  border-bottom: 1px solid var(--color-stroke);
} */

.dashboard
  .dashboard-table
  > .dashboard-row-wrapper
  > .dashboard-row
  .ant-select {
  max-width: 300px;
}

.dashboard
  .dashboard-table
  > .dashboard-row-wrapper
  > .dashboard-row
  > .dashboard-span {
  width: 300px;
}

.dashboard .dashboard-table > .dashboard-row-wrapper > .dashboard-row > a {
  color: inherit;
}

.dashboard .dashboard-table > .dashboard-row-wrapper > .dashboard-row > ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
}

.dashboard
  .dashboard-table
  > .dashboard-row-wrapper
  > .dashboard-row
  > .service-list
  > li {
  margin: 0px 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  color: var(--color-black);
}

.dashboard
  .dashboard-table
  > .dashboard-row-wrapper
  > .dashboard-row
  > .service-list
  > li:hover {
  background-color: var(--color-stroke);
  color: var(--color-primary);
}

.service-list > li > i {
  margin-right: 0.25rem;
}

.service-list > li .ant-dropdown-trigger > span {
  margin-left: 0.25rem;
  color: var(--color-black);
}

.service-list > li .ant-dropdown-trigger:hover > span {
  color: var(--color-primary);
}

.dashboard
  .dashboard-table
  > .dashboard-row-wrapper
  > .dashboard-row
  > .playlist-type {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1em;
  color: var(--color-active);
  min-width: 200px;
  border: none;
  text-align: center;
}

.dashboard
  .dashboard-table
  > .dashboard-row-wrapper
  > .dashboard-row
  > ul
  > li {
  margin: 0;
  cursor: pointer;
  color: var(--color-secondary);
  display: flex;
  column-gap: 0.2rem;
  align-items: center;
  min-width: max-content;
  padding: 0.3rem 0.5rem;
}

.dashboard
  .dashboard-table
  > .dashboard-row-wrapper
  > .dashboard-row
  > ul
  > li:hover {
  color: #fff;
}
