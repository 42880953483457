.pois .pois-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.pois .pois-accordion__header {
  background-color: var(--color-primary);
}

.pois .pois-accordion__header:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.pois .pois-accordion__header:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pois .pois-accordion__header .ant-collapse-header:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s all ease;
}

.pois .pois-accordion__header .ant-collapse-header {
  color: #fff !important;
}

.pois .pois-dropdown {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.pois .pois-dropdown span {
  color: var(--color-active);
  font-size: 1em;
  font-weight: 500;
  margin-right: 0.5rem;
}

.pois .ant-select {
  display: flex;
  width: 15% !important;
  border-radius: 0.5rem !important;
  color: var(--color-active);
}

.pois .ant-select-selector {
  background-color: transparent !important;
}

.pois .ant-pagination-options-size-changer.ant-select {
  width: 8rem !important;
}

.pois .pois-header .search-bar-wrapper .button-primary {
  border: none;
  padding: 0.5rem 1.5rem;
  color: #fff;
  background-color: var(--color-primary);
  border-radius: 5px;
}

.pois .pois-header .search-bar-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
}

.pois .pois-header .search-bar-wrapper .search-bar {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-secondary);
  padding: 0.3rem 0.7rem;
  margin-right: 1rem;
}

.pois .pois-header .search-bar-wrapper .search-bar i {
  font-size: 1.5em;
  color: var(--color-active);
}

.pois .pois-header .search-bar-wrapper .search-bar input {
  outline: none;
  border: none;
  background-color: transparent;
  padding-left: 1rem;
  color: var(--color-active);
}

.pois .pois-header .search-bar-wrapper .search-bar input::placeholder {
  color: var(--color-secondary) !important;
}

.pois .pois-content {
  /* display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start; */
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 900px) {
  .pois .pois-content {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media (min-width: 1300px) {
  .pois .pois-content {
    grid-template-columns: repeat(3, 1fr);
  }
}


@media (min-width: 1500px) {
  .pois .pois-content {
    grid-template-columns: repeat(4, 1fr);
  }
}

.pois-content .pois-left {
  margin-right: 2rem;
  vertical-align: top;
  max-height: 100vh;
  width: 20%;
  padding: 1%;
  display: inline-block;
  overflow-y: auto;
}

.pois-content .pois-right {
  display: inline-block;
  width: 70%;
  justify-content: center;
}

.form-content {
  width: 200px;
}

.pois-content .pois-right .pois-map img {
  width: 100%;
}

.pois .pois-card {
  flex: 0 0 100%;
  display: flex;
  background-color: #fff;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--color-stroke);
  border-radius: 5px;
  justify-content: space-between;
  padding: 1rem;
  align-items: flex-start;
  cursor: pointer;
}

/* @media (min-width: 1120px) {
  .pois .pois-card {
    flex: 0 0 400px;
  }
} */

.pois .pois-card:hover,
.pois .pois-card.active {
  box-shadow: 10px 3px 22px -1px rgb(0 0 0 / 18%);
  -webkit-box-shadow: 10px 3px 22px -1px rgb(0 0 0 / 18%);
  -moz-box-shadow: 10px 3px 22px -1px rgba(0, 0, 0, 0.18);
}

.pois .pois-card div {
  display: flex;
}

.pois .pois-card .poi-data {
  width: 100%;
}

.pois .pois-card .poi-actions {
  align-items: center;
}

.pois .pois-card div img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 1.2rem;
}

.pois .pois-card div.poi-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pois .pois-card div.poi-details div.poi-title-wrapper {
  width: 100%;
  line-height: 30px;
  padding: 5px 0;
  position: relative;
}

.pois .pois-card div.poi-details h3 {
  margin-bottom: 0.2rem;
  color: var(--color-active);
  font-weight: 500;
  font-size: 1.2em;

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.pois .pois-card div.poi-details p {
  margin-bottom: 0.2rem;
  color: var(--color-secondary);
}

.pois .pois-card div button,
.pois .pois-card div a {
  border: none;
  background-color: transparent;
  font-size: 1.2em;
  color: var(--color-secondary);
  cursor: pointer;
}

.pois .pois-card div button:first-child,
.pois .pois-card div a:first-child {
  margin-right: 0.2rem;
}

.pois .pois-right .form-content label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.pois .pois-map {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.pois .pois-map h3 {
  text-align: center;
  color: var(--color-secondary);
  font-weight: 500;
  margin-right: 0.5rem;
  padding-top: 0.7rem;
}

.pois .pois-map i {
  color: var(--color-secondary);
  font-size: 3em;
}

.pois .map-wrapper {
  width: 100%;
  overflow: auto;
}

.modal-drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px dashed var(--color-secondary);
  width: 100%;
  padding: 2rem;
  border-radius: 10px;
  color: var(--color-black);
  cursor: grab;
}

.modal-drop:hover,
.modal-drop.active {
  border-color: var(--color-primary);
}

.modal-drop > p {
  margin: 0px;
}

.modal-drop > button {
  margin-top: 1rem;
}

.modal-poi-upload .ant-modal-body > span {
  width: 100% !important;
}

.modal-poi-upload .ant-upload {
  width: 100% !important;
}

.upload-loading {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.upload-loading > p > i {
  margin-right: 1rem;
}

.pois .pois-pagination {
  display: flex;
  justify-content: flex-end;
}

.pois .empty-pois-state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}
