.respondent {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  padding: 1.6875rem 1.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .button {
      background-color: var(--color-primary);
      width: 8rem;
    }
  }

  &__title {
    color: var(--color-active) !important;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
  }

  &__line {
    width: 100%;
    border-top: 1px solid hsla(0, 0%, 90%, 1);
  }
}
